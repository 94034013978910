import { collection, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { db } from '../utils/firebase/firebase';
import LandingComponent from './components/loginComponent';

const OuterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100vh;
  @media screen and (max-width:800px) {
    justify-content:center;
    }
`;

const InnerDiv = styled.div`
    display: flex;
    width: 100%;
    height:400px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; // used to be top
    gap:40px;
`;

export default function LoginPage() {
    const [users, setUsers] = useState([]);
    const [userEmails, setUserEmails] = useState([]);

    useEffect(() => {
        const q = collection(db, 'users');
        const unsub = onSnapshot(q, (querySnapshot) => {
            setUsers(querySnapshot.docs.map((doc) => (
                doc.data()
            )));

            setUserEmails(querySnapshot.docs.map((doc) => (
                doc.data().email
            )));
        });
        return () => unsub;
    }, [])

    return (
        <OuterDiv>
            <InnerDiv>
                <LandingComponent users={users} userEmails={userEmails}></LandingComponent>
            </InnerDiv>
        </OuterDiv>
    )
}
