import { motion } from "framer-motion";
import React from 'react';
import styled from 'styled-components';
//Text Animation

export const LargeText = styled(motion.span)`
   color: #FFFFFF;
   font-size: 34px;
   text-align: center;
   font-weight: 500;
   width: 100%;
   height: auto;
   padding-bottom: 12px;
   line-height: 40px;
   @media screen and (max-width:600px) {
    font-size: 2rem;
    line-height: 2.2rem;
  }
`;

const TextBox = styled(motion.span)`
    padding-bottom: 12px;
`;

function TextAnimation({ line1 }) {

    const sentance = {
        hidden: { opacity: 1 },
        visable: {
            opacity: 1,
            transition: {
                delay: 0.5,
                staggerChildren: 0.1
            }
        }
    }

    const letter = {
        hidden: { opacity: 0, y: 50 },
        visable: {
            opacity: 1,
            y: 0
        }
    }

    return (
        <TextBox
            variants={sentance}
            initial="hidden"
            animate="visable"
        >
            {
                line1 ?

                    line1.split("").map((char, index) => {
                        return (
                            <LargeText key={char + "" + index} variants={letter}>
                                {char}
                            </LargeText>
                        )
                    })
                    :
                    <></>

            }
        </TextBox>
    )
}

export default TextAnimation