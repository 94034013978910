import styled from "styled-components"


export const WhiteButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    gap: 10px;
    border-radius: 5px;
    color: #000000;
    font-size: 1.1rem;
    font-weight: 400;
    height: ${props => props.height};
    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    box-shadow: 0 4px 4px 0 #ffffff30;
    background-position: center;
    border: 1px solid transparent; /* Add this line */

    cursor: pointer;
    &:hover{
       border-color: #f1f1f1;
       color: #f1f1f1;
       transition: 0.2s linear;
       background: #0a0a0a radial-gradient(circle, transparent 1%, #f1f1f120 1%) center/15000%;
    }


    &:active {
        background-color: #f1f1f120;
        background-size: 100%;
        transition: background 0s;
    }


    @media screen and (max-width:600px) {
      font-size: 1.2rem;
      height: ${props => props.heightMobile};
      width: ${props => props.widthMobile};
    }
`

export const RedButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(26,26,26,0.6);
    border: 2px solid #ff4d5a;
    border-radius: 5px;
    color: #f1f1f1;
    font-size: 1.1rem;
    font-weight: 400;
    //width: 135px;
    height:42px;
    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    gap:10px;
    background: linear-gradient(55deg,  #ff4d5a 50%, #ffffff00 50%) right;
    background-size: 300%;
    transition: .6s ease-out;

  &:hover {
    cursor: pointer;
    scale: 1.02;
    background-position: left;
  }

    @media screen and (max-width:600px) {
      width: 180px;
      font-size: 1.2rem;
    }
`
