import React from 'react';
import Layout from '../Layout/Layout';
import LoginPage from '../components/loginPage';

export default function Home() {
  return (
    <>
      <LoginPage />
    </>
  );
}

Home.getLayout = function getLayout(page) {
  return (
    <Layout>
      {page}
    </Layout>
  )
}