import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';
import styled from 'styled-components';
import LanguageContext from '../../../context/languageContext';
import { loginUser } from '../../utils/firebase/firebase';
import delay from '../../utils/reuseable/delay';
import TextAnimation, { LargeText } from './textAnimation';
import { sxPropLanding } from '../../utils/reuseable/SxProps';
import { RedButton } from '../../utils/reuseable/buttons';
import { useRouter } from 'next/router';

const theme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            desktop: 450,
        },
    },
});

const AnimatePassword = {
    hidden: { opacity: 1, y: 0 },
    visable: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1,
            delay: 0.5,

        }
    }
}

const OuterDiv = styled(motion.div)`
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width:500px;
    gap:12px;

    @media screen and (max-width:600px) {
        width:300px;
      font-size: 1.2rem;
    }
   
`


const TextWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 20px;
  color: #f1f1f199;
  font-size: 0.9rem;
`;

const BottomWrap = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  height: fit-content;
`;

const TextDiv = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  border-bottom: 1px solid #333D4A;
  margin-bottom: 10px;

`;

const sxPassword = {
    //When focused
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ff4d5a"
    },

    //When not in focus
    "& .MuiOutlinedInput-notchedOutline": {
        border: '0.5px solid #888BA3',
    },

    backgroundColor: 'rgba(230, 232, 250, 0.07)',
    color: 'white',

}


export default function LandingComponent({ users, userEmails }) {
    const [userEmail, setUserEmail] = useState('');
    const [alert, setAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({
        message: '',
        status: ''
    });
    const [loading, setLoading] = useState(false);
    const [hidden, setHidden] = useState(true)
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState();
    const [password, setPassword] = useState('');
    const { language } = useContext(LanguageContext);
    const history = useRouter();

    ////console.log(`signed in as ${currentUser?.uid}`)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (userEmail.includes('@') && userEmail.includes('.')) {
            if (userEmails.includes(userEmail)) {
                setHidden(false)
                setUser(users.find((obj) => {
                    return obj.email == userEmail
                })
                )
            }
            else {
                setHidden(true)
                setUser({})
            }
        } else {
            setHidden(true)
            setUser({})
        }
    }, [userEmail])

    async function handleSubmit(event) {
        event.preventDefault()
        setLoading(true)

        if (!hidden) {
            const userLogin = await loginUser(user.email, password)
            setLoading(true)

            const uid = userLogin?.user?.uid

            if (userLogin.status == 'success' && uid) {
                const client = uid?.split('-')[0]
                const access = uid?.split('-')[1]
                if (access == 'admin') {
                    history.push(`/${client}/${access}`)
                } else {
                    history.push(`/${client}`)
                }
            } else {
                await manageAlert(userLogin)
            }
        }

        setLoading(false)
    }

    function handleKeypress(e) {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            handleSubmit(e);
        }
    };

    async function manageAlert(prop) {
        setAlertProps({ message: prop.message, status: prop.status });
        setAlert(true);
        await delay(2000)
        setAlert(false)
        setAlertProps({ message: '', status: '' });
    }
    // 


    return (
        <ThemeProvider theme={theme}>
            <OuterDiv>
                {
                    alert ?
                        <>
                            <Alert severity={alertProps.status} variant="outlined" sx={{ color: 'white', width: '90%', mb: 2 }}>{alertProps.message}</Alert>
                        </>
                        :
                        <TextDiv>
                            {
                                hidden ?
                                    <LargeText>
                                        {language == 'swe' ? 'Ange din email' : 'Input your email'}
                                    </LargeText>
                                    :
                                    <TextAnimation line1={user ? user.name : 'No user found'}></TextAnimation>

                            }
                        </TextDiv>
                }
                <TextField
                    autoFocus={true}
                    fullWidth
                    label={language == 'swe' ? 'email' : 'email'}
                    sx={sxPropLanding}
                    type="email"
                    inputProps={{ maxLength: 40 }}
                    value={userEmail}
                    onKeyDown={(e) => handleKeypress(e)}
                    onChange={(e) =>
                        setUserEmail(e.target.value)
                    }
                />
                <BottomWrap
                    variants={AnimatePassword}
                    initial="hidden"
                    animate="visable"
                >
                    {
                        hidden ?
                            <></>
                            :
                            <BottomWrap
                                variants={AnimatePassword}
                                initial="hidden"
                                animate="visable"
                            >
                                <FormControl sx={{ m: 1, }} variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-password" sx={{ color: '#878A9D', '&.Mui-focused': { color: '#F9F7F5' } }}>Password</InputLabel>
                                    <OutlinedInput
                                        sx={sxPassword}
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        autoComplete='off'
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff sx={{ color: 'white' }} /> : <Visibility sx={{ color: '#ffffff99' }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label={language == 'swe' ? 'Lösenord' : 'Password'}
                                        value={password}
                                        onKeyDown={(e) => handleKeypress(e)}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        autoFocus={true}
                                    />
                                </FormControl>
                                <RedButton width={'50%'} onClick={handleSubmit}>{language == 'swe' ? 'Fortsätt' : 'Continue'}
                                    {
                                        loading ?
                                            <CircularProgress style={{ height: 18, width: 18, color: "#ffffff" }} />
                                            :
                                            <BiRightArrowAlt style={{ fill: '#FF4D59' }} />
                                    }
                                </RedButton>
                            </BottomWrap>
                    }
                </BottomWrap>
                <TextWrap>
                    <Typography id="modal-modal-title" sx={{ color: 'inherit', fontSize: 'inherit', textAlign: 'center', p: 0, width: '100%' }}>
                        {language == 'swe' ? 'Frågor?' : 'Questions?'}
                    </Typography>
                    <Typography type='a' id="modal-modal-title" sx={{ color: 'inherit', textDecorationLine: 'underline', whiteSpace: 'nowrap', fontSize: 'inherit', textAlign: 'center', p: 0 }}>
                        {language == 'swe' ? ' Kontakta mig' : ' Contact me'}
                    </Typography>
                </TextWrap>

            </OuterDiv>
        </ThemeProvider>

    );

}


